import React, { useContext, useState } from "react";
import "./style.css";
import { HomeContext } from "../../../../../App";

// import { data } from '../../data'

const Howitworks = ({ onButtonClick }) => {
  const data = useContext(HomeContext);

  const whyUsSection = data.section2_img

  let newArray = whyUsSection.split(',');

  const [isHover, setIsHover] = useState(false);
  // const whyUsSection = data.customization.whyUsSection
  const howitworksSectionContent = data.customization.howitworks.content;
  const howitworksSectionSty = data.customization.howitworks.style;

  const titleColor = {
    color: howitworksSectionSty.titleColor,
  };
  const themeColor2 = {
    color: howitworksSectionSty.themeColor2,
  };

  const stepTextStyle = {
    color: howitworksSectionSty.stepTextColor,
  };
  const outerBackground = {
    background: howitworksSectionSty.outerBgcolor,
  };

  const sectionParatext = {
    color: howitworksSectionSty.paraTextColor,
   
  };



  const ctaStyle = {
    backgroundColor:
      howitworksSectionSty.ctaBg
      ? howitworksSectionSty.ctaBg :"",
    backgroundColor: isHover
      ? howitworksSectionSty.ctaHoverBg
      : howitworksSectionSty.ctaBg,
    color: isHover
      ? howitworksSectionSty.ctaHoverTextColor
      : howitworksSectionSty.ctaTextColor,
    border: "1px solid",
    borderTopColor: isHover
      ? howitworksSectionSty.ctaHoverBg
      : howitworksSectionSty.ctaBg,
    borderLeftColor: isHover
      ? howitworksSectionSty.ctaHoverBg
      : howitworksSectionSty.ctaBg,
    borderRightColor: isHover
      ? howitworksSectionSty.ctaHoverBg
      : howitworksSectionSty.ctaBg,
    borderBottomColor: isHover
      ? howitworksSectionSty.ctaHoverBg
      : howitworksSectionSty.ctaBg,
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <>
      {/* <!-- 5th --> */}
      <div
        className="container-fluid m-0 p-0 MobilResponsiveDisplay1 bolck_bg website_blocks"
        style={outerBackground}
      >
        <div className="container ResponsivFristBlock addPaddingBlock">
          <h1
            className="all_blcok_head all_block_headRequest"
            style={titleColor}
          >
            {/* How to  */}
            {howitworksSectionContent.titleText}{" "} 
            <span className="AmountColor" style={themeColor2}>
              {/* Request */}
              {howitworksSectionContent.titleTextTheme}
            </span>
          </h1>

          <div className="Main_Div_Number_Img ">
            <div className="row BoxParentStyle">
              <div className="col-sm col-md col-lg-4 col-xl-4 TabResponsive">
                <div className="SingleBoxInnerCol5th ">
                  <p className="steps_text1 requeststeps" style={stepTextStyle}>
                    {/* STEP 1 */}
                    {howitworksSectionContent.step1}
                  </p>
                  <span className="requesticon_bg">
                    <img src={`static/assets/images/${newArray[0]}`} alt="" />
                  </span>
                  <h1
                    className="BoxHeadding"
                    //  style={stepHeadingText}
                  >
                    {/* Submit your information */}
                    {howitworksSectionContent.step1headtext}
                  </h1>
                  <p className="BoxPara" style={sectionParatext}>
                    {/* Fill out our simple and secure form to send your information
                                        to lenders. */}
                    {howitworksSectionContent.step1paratext}
                  </p>
                </div>
              </div>

              <div className="col-sm col-md col-lg-4 col-xl-4 TabResponsive">
                <div className="SingleBoxInnerCol5th ">
                  <p className="steps_text1 requeststeps" style={stepTextStyle}>
                    {/* STEP 2 */}
                    {howitworksSectionContent.step2}
                  </p>
                  <span className="requesticon_bg">
                  <img src={`static/assets/images/${newArray[1]}`} alt="" />
                  </span>
                  <h1
                    className="BoxHeadding"
                    // style={stepHeadingText}
                  >
                    {howitworksSectionContent.step2headtext}

                    {/* Get approval */}
                  </h1>
                  <p
                    className="BoxPara"
                    style={sectionParatext}
                  >
                    {howitworksSectionContent.step2paratext}

                    {/* Lenders will approve or deny your loan request as soon as
                                        next business day. */}
                  </p>
                </div>
              </div>

              <div className="col-sm col-md col-lg-4 col-xl-4 TabResponsive">
                <div className="SingleBoxInnerCol5th RemovemarginProcess ">
                  <p className="steps_text1 requeststeps" style={stepTextStyle}>
                    {/* STEP 3 */}
                    {howitworksSectionContent.step3}
                  </p>
                  <span className="requesticon_bg">
                  <img src={`static/assets/images/${newArray[2]}`} alt="" />
                  </span>
                  <h1
                    className="BoxHeadding"
                    // style={stepHeadingText}
                  >
                    {/* Check your account */}
                    {howitworksSectionContent.step3headtext}
                  </h1>
                  <p
                    className="BoxPara"
                    style={sectionParatext}
                  >
                    {/* If your request is approved, the fund get directly
                                        transferred
                                        into your
                                        account in as soon as next business day. */}
                    {howitworksSectionContent.step3paratext}
                  </p>
                </div>
              </div>
            </div>
            <div className="GetstartButtonContainer_step">
              <a>
                <button
                  className="WhiteButtonGetStart"
                  style={ctaStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                >
                  
                  {howitworksSectionContent.CTAbuttonText}
                  <img
                    src="static/assets/images/Arrow.svg"
                    alt=""
                    className="img-fluid cus_sty_getstart"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- 5th Mobile --> */}
      <div
        className="container-fluid m-0 p-0 MobilResponsiveDisplay2 bolck_bg website_blocks"
        style={outerBackground}
      >
        <div className="container ResponsivFristBlock addPaddingBlock">
          <h1
            className="all_blcok_head all_block_headRequestMob"
            style={titleColor}
          >
            
            {howitworksSectionContent.titleText}{" "} 
            <span
              className="AmountColor"
             style={themeColor2}
            >
              {howitworksSectionContent.titleTextTheme}
            </span>
          </h1>

          <div className="d-flex magin_three_img">
            <div className="Main_Div_Number_Img  ">
              <div className="Display_nuber_Img">
                <img
                  src="static/assets/images/STEP 1.svg"
                  alt=""
                  className="MobileImgWidthNumber"
                />
                <div className="saprater seperator1"></div>

                <img
                  src="static/assets/images/STEP 2.svg"
                  alt=""
                  className="MobileImgWidthNumber"
                />
                <div className="saprater seperator2"></div>

                <img
                  src="static/assets/images/STEP 3.svg"
                  alt=""
                  className="MobileImgWidthNumber"
                />
              </div>
            </div>
            <div className="row BoxParentStyle StyleMob5th">
              <div className="col-sm- col-md- col-lg-4 col-xl-4 p-0 AddmarginMobileRes">
                <div className="">
                  <span className="requesticon_bg">
                    <img
                      src={`static/assets/images/${newArray[0]}`}
                      alt=""
                      className="MobileImgWidth"
                    />
                  </span>
                  <h1 className="BoxHeadding">{howitworksSectionContent.step1headtext}</h1>
                  <p className="BoxPara" style={sectionParatext}>
                    {howitworksSectionContent.step1paratext}
                  </p>
                </div>
              </div>
              <div className="col-sm- col-md- col-lg-4 col-xl-4 p-0 AddmarginMobileRes">
                <div className="TopPaddingMob">
                  <span className="requesticon_bg">
                    <img
                      src={`static/assets/images/${newArray[1]}`}
                      alt=""
                      className="ResposivePadding MobileImgWidth"
                    />
                  </span>
                  <h1 className="BoxHeadding">{howitworksSectionContent.step2headtext}</h1>
                  <p className="BoxPara" style={sectionParatext}>
                    {howitworksSectionContent.step2paratext}
                  </p>
                </div>
              </div>
              <div className="col-sm- col-md- col-lg-4 col-xl-4 p-0 AddmarginMobileRes">
                <div className="TopPaddingMob">
                  <span className="requesticon_bg">
                    <img
                      src={`static/assets/images/${newArray[2]}`}
                      alt=""
                      className="ResposivePadding MobileImgWidth"
                    />
                  </span>
                  <h1 className="BoxHeadding">{howitworksSectionContent.step3headtext}</h1>
                  <p className="BoxPara" style={sectionParatext}>
                    {howitworksSectionContent.step3paratext}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="GetstartButtonContainer_step">
            <a >
              <button className="WhiteButtonGetStart" style={ctaStyle} onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave} onClick={handleClick}>
                {howitworksSectionContent.CTAbuttonText}
                <img
                  src="static/assets/images/Arrow.svg"
                  alt=""
                  className="img-fluid cus_sty_getstart"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworks;
